<template>
  <div class="bg1">
  <div  class="content">
    <div class="w1200">
      <div class="about">关于我们<br><span class="abc">ABOUT US</span></div>
      <div class="flex">
        <div class="czi flex-1">{{data.aboutUs}}</div>
        <div class="cimg"></div>
      </div>
    </div>
    <div class="chanpin">
      <div class="about">产品展示<br><span class="abc">PRODUCTS</span></div>
      <div class="chan1">
        <div class="products" v-for="(pr,index) in product">
          <img :src="pr.picLink"/>
        </div>
      </div>
    </div>
    <div class="w1200">
    <div class="about">民企招聘<br><span class="abc">RECRUITMENT</span>
      <div class="gengduo" @click="goto(1)">更多<br>MORE</div>
    </div>
    <div style="overflow: hidden">
      <div class="mqzp flex" v-for="(li,index) in ul">
        <div class="flex-1">
          <div class="title">{{li.title}}</div>
          <div class="h1">
            <span v-for="(item,ins) in li.h1">{{item.text}}</span>
          </div>
          <div class="h2">
            <span v-for="(item,ins) in li.h2">{{item.text}}</span>
          </div>
        </div>
        <div class="right">
          <div class="money">6000-8000元/月</div>
          <div class="btn">申请</div>
        </div>
      </div>
    </div>
    </div>
    <div class="w1200">
    <div class="about">供需交易<br><span class="abc">SUPPLY AND DEMAND</span>
      <div class="gengduo" @click="goto(2)">更多<br>MORE</div>
    </div>
    <div>
      <el-table
          :data="tableData"
          :show-header="false"
          style="width: 100%;text-align: center">
        <el-table-column
            prop="date"
            label=""
            width="180">
        </el-table-column>
        <el-table-column
            prop="name"
            label=""
            width="180">
        </el-table-column>
        <el-table-column
            prop="address"
            label="">
        </el-table-column>
        <el-table-column
            prop="address"
            label="">
        </el-table-column>
        <el-table-column
            prop="address"
            label="">
        </el-table-column>
        <el-table-column
            fixed="right"
            width="130">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="danger" size="mini">我要咨询</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    </div>
    <div class="gs">
      <div class="gsxx">
          <p>工商信息:数据由天眼查提供</p>
        <p>公司名称：河北冀来钢管厂</p>
        <p>注册资本：100万人民币</p>
        <p>成立日期：2016-05-17</p>
        <p>企业法人：朱在旺</p>
        <p>经营状态：存续（在营、开业、在册）</p>
        <p>注册地址：北京市延庆区康庄镇龙庆·望都佳园5号楼1层2单元202</p>
        <p>经营范围： 组织文化艺术交流活动；销售首饰、文化用品、体育用品、电子产品、计算机、软件及辅助设备、汽车零配件、家具、针纺织品、日用品、玩     具、体育器材、乐器、五金、工艺品、收藏品、黄金制品、建筑材料；贸易咨询；技术开发、技术推广、技术服务、技术转让、技术咨询；承办展览展示；设计、制作、代理、发布广告；音像制品制作；出版物零售。（市场主体依法自主选择经营项目，开展经营活动；出版物零售、音像制品制作以及依法须经批准的项目，经相关部门批准后依批准的内容开展经营活动；不得从事国家和本市产业政策禁止和限制类项目的经营活动。）</p>
      </div>
    </div>
    <div class="w1200 mzsm">
      <div>
        免责声明：
      </div>
      <div>
        <p>本网站作为信息服务平台，所有职位信息均由企业自行上传、发布。</p>
        <p>用户发布信息的真实性、准确性等不作保证。</p>
        <p>因上述原因导致的对其他用户或第三方造成的损失，本网站不承担责任。</p>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import {getProductListByCreditCode} from "../../request/moudle";

export default {
  name: "chome",
  data(){
    return {
      product:[],
      data: this.common.getSessionStorage('enterpriseInfo'),
      tableData:[{
        date:'采购标题',
        name:'采购-集采',
        address:'3000元/吨'
      },{
        date:'采购标题',
        name:'采购-集采',
        address:'3000元/吨'
      },{
        date:'采购标题',
        name:'采购-集采',
        address:'3000元/吨'
      }],
      ul:[{
        title:'月薪过万包食宿招聘操作工',
        h1:[{text:'年底双薪'}],
        h2:[{text:'货车司机'},{text:'学历不限'},{text:'无经验要求'}]
      },{
        title:'月薪过万包食宿招聘操作工',
        h1:[{text:'年底双薪'}],
        h2:[{text:'货车司机'},{text:'学历不限'},{text:'无经验要求'}]
      },{
        title:'月薪过万包食宿招聘操作工',
        h1:[{text:'年底双薪'}],
        h2:[{text:'货车司机'},{text:'学历不限'},{text:'无经验要求'}]
      },{
        title:'月薪过万包食宿招聘操作工',
        h1:[{text:'年底双薪'}],
        h2:[{text:'货车司机'},{text:'学历不限'},{text:'无经验要求'}]
      },{
        title:'月薪过万包食宿招聘操作工',
        h1:[{text:'年底双薪'}],
        h2:[{text:'货车司机'},{text:'学历不限'},{text:'无经验要求'}]
      },{
        title:'月薪过万包食宿招聘操作工',
        h1:[{text:'年底双薪'}],
        h2:[{text:'货车司机'},{text:'学历不限'},{text:'无经验要求'}]
      }]
    }
  },
  created() {
    this.products()
  },
  methods:{
    products(){
      this.$api.index.getProductListByCreditCode({
        creditCode:'91130983731438081Y'
      }).then((res)=>{
        this.product=res.data.splice(0,4)
      })
    },
    goto(num){
      if(num==1){
        this.$router.push({
          path:'/czp'
        })
      }else if(num==2){
        this.$router.push({
          path:'/cgx'
        })
      }
    },
    handleClick(){

    }
  }
}
</script>

<style lang="scss" scoped>
.chanpin{
  background: #F5F5F7;
  overflow: hidden;
  .chan1{
    width: 1200px;
    margin: 0px auto;
  }
  .products{
    width: 240px;
    height: 240px;
    float: left;
    margin-left: 25px;
    img{
      width: 100%;
      height: 100%;
    }
  }
}

.bg1{
  background: url("../../assets/home/compyindex.png") no-repeat;
  background-position-y: -345px;
}
.gs{
  background: #F5F5F7;
  padding: 20px 0;
  margin-top:50px;
}
.gsxx{
  width: 1200px;
  margin: 0 auto;
  color: #4C4C4C;
  line-height: 30px;
}
.mzsm{
  padding: 50px 30px;
  color: #4C4C4C;
  line-height: 30px;
}
.w1200{
  width: 1200px;
  margin: 0px auto;
}
  .content{
    padding-top: 50px;
    overflow: hidden;
    .about{
      font-size: 24px;
      font-weight: 501;
      text-align: center;
      padding-bottom: 30px;
      position: relative;
      .gengduo{
        position: absolute;
        font-size: 14px;
        color: #0068DD;
        right: 30px;
        bottom: 6px;
        cursor: pointer;
      }
    }
    .abc{
      font-size: 16px;
    }
    .czi{
      padding: 35px;
      color: #4C4C4C;
    }
    .cimg{
      width: 560px;
      height: 348px;
      background: #fff;
    }
    .mqzp{
      font-size: 14px;
      padding: 30px 22px;
      width: 519px;
      float: left;
      overflow: hidden;
      background: linear-gradient(0deg,#FFFFFF,#EEF1F5);
      margin-left: 36px;
      margin-bottom: 40px;
      border-radius: 8px;
      .title{
        font-size: 20px;
        color: #292929;
      }
      .h1{
        color: #148BD0;
        line-height: 40px;
        span{
          margin-right: 15px;
        }
      }
      .h2{
        color: #6D6D6D;
        span{
          margin-right: 8px;
          padding-right: 8px;
          border-right: 1px solid #6D6D6D;
        }
        span:last-child{
          border:none;
        }
      }
      .right{
        width: 120px;
        text-align: right;
        .money{
          color: #FF4747;
        }
        .btn{
          display: inline-block;
          background: #D83434;
          border-radius: 4px;
          color: #fff;
          padding: 3px 8px;
          margin-top: 40px;
        }
      }
    }
  }
</style>